<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <div style="height: 10px;"></div>
        {{caption}}
        <div class="card-header-actions">
          <div style="float: right; font-size: 12px;">
            <table>
              <tr>
                <td>
                    <v-date-picker                    
                      v-model="range"    
                      class="date-picker"
                      locale="es-MX" size="sm"          
                      mode="range"
                      style="width: 210px; font-size: 12px !important;"
                    />  
                </td>
                <td>
                  <CSelect
                    :value.sync="direction_code"
                    :options="directionsOptions"
                    invalidFeedback="Este campo es requerido."
                    style="width: 210px; font-size: 12px !important; margin-bottom: 0px;"
                    @change="changeFilters"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #number="{item}"><td class="text-center w100">{{item.number}}</td></template>
        <template #date="{item}"><td class="text-center w100">{{item.date | date}}</td></template>
        <template #citizen="{item}"><td class="text-center">{{item.citizen}}</td></template>
        <template #direction="{item}"><td class="text-center">{{item.direction}}</td></template>
        <template #manager="{item}"><td class="text-center">{{item.manager_id == 0 || item.manager == null || item.manager == undefined ? '-' : item.manager}}</td></template>
        <template #stage="{item}"><td class="text-center w100">{{item.stage}}</td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="info" size="sm" @click="viewTicket(item)"><CIcon name="cil-folder-open"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import ws from '../../services/tickets';
import store from '../../store'
import { DatePicker } from 'v-calendar'
import moment from 'moment';
import moment_timezone from 'moment-timezone';

export default {
  name: 'TicketsTable',
  components: { 'v-date-picker': DatePicker },
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'number', label: 'No.'},
          {key: 'date', label: 'Fecha'},
          {key: 'citizen', label: 'Ciudadano'},
          {key: 'direction', label: 'Dirección'},
          {key: 'manager', label: 'Responsable'},
          {key: 'stage', label: 'Estado'},
          {key: 'actions', label: 'Acciones'}
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    stage: {
      type: String,
      default: 'active'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
      direction_code: '',
      directions: [],
      directionsOptions: [],
      range: {
        start: new moment().startOf('month').toDate(),
        end: new moment().endOf("month").toDate(),
      }
    }
  },
  mounted: async function() {
    this.loading();

    let response = await ws.getDirections();

    if(response.type == "success"){
      this.directions = response.data;
    }

    this.directionsOptions = await this.$parseSelectOptionsOrdered(this.directions, "id", "name", [{"label": "Todas","value":""}]);

    this.loaded();
  },
  methods: {
    changeFilters: async function(event){
      this.loading();

      let dates = [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")];

      let response = await ws.getFilteredTickets(dates, this.direction_code, this.stage);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.loaded();
    },
    viewTicket(ticket){
      window.location.href = "/#/tickets/"+ticket.code+"/view";
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  watch: {
    range: async function(){
      await this.changeFilters();
    }
  }
}
</script>
<style>
  .date-picker input{
    height: 32px;
    font-size: 12px !important;
  }

  select{
    height: 32px;
    font-size: 12px !important;
    margin-bottom: 0px;
  }
</style>